// src/routes/profile/ProfileCard.tsx
import React, { useState, useEffect, useRef } from "react";
import Avatar from "boring-avatars";
import { Button, Modal } from "react-bootstrap";
import EditProfile from "./EditProfile";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { UserData, DisplayUser } from "./types";

interface ProfileCardProps {
  user: DisplayUser;
  user_data: UserData;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ user, user_data }) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [university, setUniversity] = useState<string>("");
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { name, email, phone, website, linkedin } = user;
  const { UserID } = user_data;

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const handleImageUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      // Upload the file
      const formData = new FormData();
      formData.append("file", file);

      // Get CSRF token from cookies
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];

      try {
        await axiosInterceptor.post(`images/upload/${UserID}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": csrfToken || "",
          },
        });
        // After successful upload, fetch the updated image
        fetchProfileImage();
      } catch (error) {
        console.error("Error uploading profile image:", error);
        alert("Failed to upload profile image. Please try again.");
      }
    } else {
      alert("Please select a JPG or PNG image");
    }
  };

  const fetchProfileImage = async () => {
    try {
      // Get the list of user's images with pre-signed URLs using the correct path structure
      const response = await axiosInterceptor.get(
        `serve_private_doc/user_career_data/user_id=${UserID}/images/`
      );
      if (
        response.data?.private_docs &&
        response.data.private_docs.length > 0
      ) {
        // private_docs is an array of [presignedUrl, filename] pairs
        // Get the first image's pre-signed URL
        const [presignedUrl] = response.data.private_docs[0];
        setProfileImage(presignedUrl);
      }
    } catch (error) {
      console.error("Error fetching profile image:", error);
      setProfileImage(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch education data
        const eduResponse = await axiosInterceptor.get(
          `get_user_education/${UserID}/`
        );
        setUniversity(eduResponse.data[0]["school"] || "Unknown University");

        // Fetch profile image
        await fetchProfileImage();
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [UserID]);

  return (
    <div className="flex flex-col w-full gap-y-3 p-6 rounded-lg shadow-card bg-white">
      <div className="w-2/3 mx-auto mb-4">
        <div className="mx-auto mb-4 relative group" style={{ width: "100%" }}>
          {profileImage ? (
            <img
              src={profileImage}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
              onError={(e) => {
                console.error("Error loading image:", e);
                setProfileImage(null);
              }}
            />
          ) : (
            <Avatar
              name={UserID}
              variant="beam"
              size="100%"
              colors={["#99CBFF", "#EDEDED"]}
            />
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept=".jpg,.jpeg,.png"
          onChange={handleFileChange}
        />
        <div className="flex justify-center">
          <Button variant="light" onClick={handleImageUpload}>
            <i className="mr-2 bi bi-pencil-square"></i>
            Edit
          </Button>
        </div>
      </div>
      <h4 className="text-2xl font-medium">{name}</h4>
      <p className="text-sm font-medium -mt-3 mb-2">{university}</p>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">Email</label>
        <p className="text-lg font-medium text-black">{email}</p>
      </div>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">Phone</label>
        <p className="text-lg font-medium text-black">{phone}</p>
      </div>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">Website</label>
        <p className="text-lg font-medium text-black">{website}</p>
      </div>
      <div className="flex flex-col gap-y-0.5">
        <label className="text-xs font-medium text-gray-500">LinkedIn</label>
        <p className="text-lg font-medium text-black">{linkedin}</p>
      </div>
      <Button
        variant="light"
        className="float-right ml-auto mr-0"
        onClick={handleShowEdit}
      >
        <i className="mr-2 bi bi-pencil-square"></i>
        Edit
      </Button>
      <Modal show={showEdit} onHide={handleCloseEdit} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditProfile existingInfo={user_data} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfileCard;
