// src/routes/profile/Profile.tsx
import React, { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard";
import PersonalInfoCard from "./PersonalInfoCard";
import "../../assets/userAssets/main.css";
import CareerProfile from "./CareerProfile";
import axiosInterceptor from "../../utils/axiosInterceptor";
import ScreenSpinner from "../../components/ScreenSpinner";
import SurveyLink from "./surveys/SurveyLink";
import PersonalityScoreCard from "./PersonalityScoreCard";
import ChecklistCard from "./ChecklistCard";
import { UserData, DisplayUser } from "./types"; // Import the unified interfaces

// Question mapping (text to number)
const questionMapping: Record<string, number> = {
  "Am the life of the party.": 1,
  "Feel little concern for others.": 2,
  "Am always prepared.": 3,
  "Get stressed out easily.": 4,
  "Have a rich vocabulary.": 5,
  "Don't talk a lot.": 6,
  "Am interested in people.": 7,
  "Leave my belongings around.": 8,
  "Am relaxed most of the time.": 9,
  "Have difficulty understanding abstract ideas.": 10,
  "Feel comfortable around people.": 11,
  "Insult people.": 12,
  "Pay attention to details.": 13,
  "Worry about things.": 14,
  "Have a vivid imagination.": 15,
  "Keep in the background.": 16,
  "Sympathize with others' feelings.": 17,
  "Make a mess of things.": 18,
  "Seldom feel blue.": 19,
  "Am not interested in abstract ideas.": 20,
  "Start conversations.": 21,
  "Am not interested in other people's problems.": 22,
  "Get chores done right away.": 23,
  "Am easily disturbed.": 24,
  "Have excellent ideas.": 25,
  "Have little to say.": 26,
  "Have a soft heart.": 27,
  "Often forget to put things back in their proper place.": 28,
  "Get upset easily.": 29,
  "Do not have a good imagination.": 30,
  "Talk to a lot of different people at parties.": 31,
  "Am not really interested in others.": 32,
  "Like order.": 33,
  "Change my mood a lot.": 34,
  "Am quick to understand things.": 35,
  "Don't like to draw attention to myself.": 36,
  "Take time out for others.": 37,
  "Shirk my duties.": 38,
  "Have frequent mood swings.": 39,
  "Use difficult words.": 40,
  "Don't mind being the center of attention.": 41,
  "Feel others' emotions.": 42,
  "Follow a schedule.": 43,
  "Get irritated easily.": 44,
  "Spend time reflecting on things.": 45,
  "Am quiet around strangers.": 46,
  "Make people feel at ease.": 47,
  "Am exacting in my work.": 48,
  "Often feel blue.": 49,
  "Am full of ideas.": 50,
};

// Function to transform answers before sending to backend
function transformAnswers(
  rawAnswers: Record<string, number>
): Record<number, number> {
  const transformedAnswers: Record<number, number> = {};

  Object.entries(rawAnswers).forEach(([question, answer]) => {
    const questionNumber = questionMapping[question];
    if (questionNumber) {
      transformedAnswers[questionNumber] = answer;
    }
  });

  return transformedAnswers;
}

// Function to validate answers
function validateAnswers(answers: Record<number, number>): boolean {
  // Check if we have all 50 questions
  if (Object.keys(answers).length !== 50) {
    return false;
  }

  // Check if all answers are between 1 and 5
  return Object.values(answers).every((value) => value >= 1 && value <= 5);
}

const Profile: React.FC = () => {
  const [userdata, setUserData] = useState<UserData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setIsLoading(false);
        return;
      }
      try {
        const [userResponse, surveyResponse] = await Promise.all([
          axiosInterceptor.get<{
            u_data: Partial<UserData>;
          }>(`/getuser/${userId}/`),
          axiosInterceptor.get(`/save_or_update_user_surveys/${userId}/`),
        ]);

        // Provide fallback for missing fields
        const fetchedData: UserData = {
          UserID: userId,
          firstname: userResponse.data.u_data.firstname ?? "",
          lastname: userResponse.data.u_data.lastname ?? "",
          email: userResponse.data.u_data.email ?? "",
          phone_number: userResponse.data.u_data.phone_number ?? "",
          website: userResponse.data.u_data.website ?? "",
          linkedin: userResponse.data.u_data.linkedin ?? "",
          dob: userResponse.data.u_data.dob ?? "",
          street_addr: userResponse.data.u_data.street_addr ?? "",
          city_addr: userResponse.data.u_data.city_addr ?? "",
          state_addr: userResponse.data.u_data.state_addr ?? "",
          zip_code: userResponse.data.u_data.zip_code ?? "",
          country_addr: userResponse.data.u_data.country_addr ?? "",
          password: userResponse.data.u_data.password ?? "",
          password_verify: userResponse.data.u_data.password_verify ?? "",
          has_visa:
            typeof userResponse.data.u_data.has_visa === "boolean"
              ? userResponse.data.u_data.has_visa
                ? "Yes"
                : "No"
              : userResponse.data.u_data.has_visa ?? "No",
          job_country: userResponse.data.u_data.job_country ?? [],
          github: userResponse.data.u_data.github ?? "",
          personality_scores: undefined,
        };

        // If personality survey exists, get the scores
        if (surveyResponse.data?.personality_survey) {
          try {
            console.log(
              "Raw personality survey data:",
              surveyResponse.data.personality_survey
            );

            // Parse the double-encoded JSON string
            let parsedAnswers;
            try {
              const firstParse = JSON.parse(
                surveyResponse.data.personality_survey
              );
              console.log("After first parse:", firstParse);
              parsedAnswers = JSON.parse(firstParse);
              console.log("After second parse:", parsedAnswers);
            } catch (parseError) {
              console.error(
                "Error parsing personality survey data:",
                parseError
              );
              throw parseError;
            }

            // Transform answers to the correct format
            const transformedAnswers = transformAnswers(parsedAnswers);
            console.log("Transformed answers:", transformedAnswers);

            // Validate the transformed answers
            if (!validateAnswers(transformedAnswers)) {
              console.error("Invalid or incomplete answers");
              return;
            }

            // Call the scoring endpoint
            console.log(
              "Calling scoring endpoint with answers:",
              transformedAnswers
            );
            const scoreResponse = await axiosInterceptor.post(
              "/personality-test/score/",
              {
                answers: transformedAnswers,
              }
            );

            console.log("Score response:", scoreResponse.data);
            if (scoreResponse.data?.success) {
              fetchedData.personality_scores = scoreResponse.data.scores;
              console.log(
                "Updated personality scores:",
                fetchedData.personality_scores
              );
            } else {
              console.error(
                "Scoring endpoint returned error:",
                scoreResponse.data
              );
            }
          } catch (error) {
            console.error("Error getting personality scores:", error);
          }
        } else {
          console.log("No personality survey data found");
        }

        setUserData(fetchedData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (isLoading) {
    return <ScreenSpinner />;
  }

  if (!userdata) {
    return <div>Error loading user data</div>;
  }

  // Construct DisplayUser from UserData
  const user: DisplayUser = {
    name: `${userdata.firstname} ${userdata.lastname}`,
    email: userdata.email,
    phone: userdata.phone_number,
    website: userdata.website,
    linkedin: userdata.linkedin,
    birthday: userdata.dob,
    address: `${userdata.street_addr}, ${userdata.city_addr}, ${userdata.state_addr}, ${userdata.zip_code}, ${userdata.country_addr}`,
    visa: userdata.has_visa,
    countries: userdata.job_country,
    personality_scores: userdata.personality_scores,
  };

  return (
    <div className="flex flex-row p-4 gap-x-5">
      <div className="w-96 flex flex-col gap-y-3">
        <ProfileCard user={user} user_data={userdata} />
        <PersonalInfoCard user={user} user_data={userdata} />
        <ChecklistCard userId={userdata.UserID} />
        <PersonalityScoreCard scores={user.personality_scores} />
        <SurveyLink text="Job Preferences" path="/job-preferences-survey" />
        <SurveyLink text="OCEAN Personality" path="/personality-survey" />
      </div>
      <div className="w-full">
        <CareerProfile />
      </div>
    </div>
  );
};

export default Profile;
