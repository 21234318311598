import React from "react";
import { Link } from "react-router-dom";

interface SurveyLinkProps {
  text: string;
  path: string; // Path for internal routing
}

const SurveyLink: React.FC<SurveyLinkProps> = ({ text, path }) => {
  return (
    <Link
      to={path}
      className="block p-2 rounded bg-[#1e40af] text-white text-center hover:bg-blue-600 no-underline"
      style={{ textDecoration: "none" }} // Ensure no underline as a fallback
    >
      {text}
    </Link>
  );
};

export default SurveyLink;