import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import TestCard from "./TestCard";

// ===================================
// Types and Interfaces
// ===================================
interface TestAttemptInfo {
  score: number;
  completion: string;
  duration: number;
}

interface TestOption {
  title: string;
  duration: string;
  description: string;
  route: string;
}

interface ChartDataPoint {
  month: string;
  [key: string]: string | number;
}

interface TooltipPayloadItem {
  dataKey: string;
  color: string;
  value: number;
  name: string;
}

// We have "technical", "background", "behavioral"
type TestType = "technical" | "background" | "behavioral";
// For attempts, we have "attempt1" or "attempt2"
type AttemptType = "attempt1" | "attempt2";

// ===================================
// Educational Levels Configuration
// ===================================
const educationalLevels = [
  { value: 0, label: "High School" },
  { value: 25, label: "Bachelors" },
  { value: 50, label: "Masters" },
  { value: 75, label: "PhD" },
  { value: 100, label: "World Class" },
];

// ===================================
// Colors Configuration
// ===================================
const colors: { [key: string]: string } = {
  computerFoundations: "#3B82F6",
  mathFoundations: "#10B981",
  dataFoundations: "#6366F1",
  softwareEngineer: "#F59E0B",
  networkEngineer: "#EC4899",
  hardwareEngineer: "#8B5CF6",
  electricalEngineer: "#14B8A6",
  aiResearch: "#EF4444",
  bioinfo: "#F97316",
  quantTrader: "#06B6D4",
  quantResearcher: "#A855F7",
};

// ===================================
// Helper Functions
// ===================================
const formatKeyToLabel = (key: string): string => {
  const labelMap: { [key: string]: string } = {
    computerFoundations: "Computer Foundations",
    mathFoundations: "Math Foundations",
    dataFoundations: "Data Foundations",
    softwareEngineer: "Software Engineer",
    networkEngineer: "Network Engineer",
    hardwareEngineer: "Hardware Engineer",
    electricalEngineer: "Electrical Engineer",
    aiResearch: "Artificial Intelligence Research",
    bioinfo: "Bioinformatics",
    quantTrader: "Quantitative Trader",
    quantResearcher: "Quantitative Researcher",
  };
  return labelMap[key] || key;
};

const getEducationalLevel = (value: number): string => {
  return educationalLevels.reduce((prev, curr) => {
    return Math.abs(curr.value - value) < Math.abs(prev.value - value)
      ? curr
      : prev;
  }, educationalLevels[0]).label;
};

// ===================================
// Custom Tooltip Component
// ===================================
interface CustomTooltipProps {
  active?: boolean;
  payload?: TooltipPayloadItem[];
  label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-gray-200 p-4 rounded shadow-lg">
        <p className="font-semibold mb-2">{`Month: ${label}`}</p>
        {payload.map((entry) => (
          <div key={entry.dataKey} className="flex items-center mb-1">
            <span
              className="w-2 h-2 rounded-full mr-2"
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm">
              {`${entry.name}: ${getEducationalLevel(entry.value)}`}
            </span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

// ===================================
// Test Content / Results Data
// ===================================
//
// This object stores all the result data for each test (background, behavioral, technical)
// and each attempt (attempt1, attempt2).
//
// * For the background/behavioral tests, we only have textual results (overview/analysis).
// * For the technical test, we have both a chart data set (progressData) and the attempt summary.
const testResults = {
  background: {
    attempt1: {
      overview: `Your background interview responses demonstrate a clear and well-articulated professional journey. You effectively highlighted your key achievements in software development, particularly your work on scalable systems and team leadership. Your educational background in Computer Science provides a strong foundation, though there's room to better connect your academic projects to real-world applications.`,
      analysis: `Your responses show strong technical depth and project ownership. Key strengths include your ability to articulate complex technical decisions and demonstrate growth through challenges. Consider providing more specific metrics and business impacts in your project discussions. Your explanation of career transitions was clear, though you could strengthen how you frame your future career goals.`,
    },
    attempt2: {
      overview: `Your second attempt at the background interview shows an improvement in framing your academic experiences to match industry needs. You provided clearer examples of real-world applications and connected those to your past roles more effectively.`,
      analysis: `Analysis indicates greater specificity in explaining technical decisions and their impact on business goals. You still have room to highlight your unique strengths with more metrics and data points, but your overall narrative is more coherent and compelling than your first attempt.`,
    },
  },
  behavioral: {
    attempt1: {
      overview: `Your behavioral interview responses reflect strong interpersonal skills and leadership potential. You demonstrated excellent communication abilities and showed good judgment in handling complex situations. Your examples effectively showcased your teamwork and ability to navigate challenging workplace dynamics.`,
      analysis: `Analysis shows particular strengths in Communication (92%), Leadership (88%), and Teamwork (90%). Areas for development include Client Focus (75%) and Working Under Pressure (78%). Your responses demonstrate high emotional intelligence and strong initiative, though there's room to improve in articulating conflict resolution strategies. Your examples of project leadership were particularly compelling.`,
    },
    attempt2: {
      overview: `On your second attempt, you continued to display strong interpersonal skills. You provided more structured examples of how you collaborate with cross-functional teams and how you adapt to new challenges.`,
      analysis: `Analysis highlights improved clarity in discussing conflict resolution and client focus, with slightly higher scores in these areas. Communication (94%) and Teamwork (92%) remain your top competencies. Keep refining your leadership narratives with more tangible outcomes and measurable successes.`,
    },
  },
  technical: {
    attempt1: {
      progressData: [
        {
          month: "May",
          computerFoundations: 85,
          mathFoundations: 70,
          dataFoundations: 75,
          softwareEngineer: 82,
          networkEngineer: 78,
          hardwareEngineer: 73,
          electricalEngineer: 68,
          aiResearch: 88,
          bioinfo: 72,
          quantTrader: 90,
          quantResearcher: 85,
        },
        {
          month: "June",
          computerFoundations: 87,
          mathFoundations: 72,
          dataFoundations: 77,
          softwareEngineer: 84,
          networkEngineer: 80,
          hardwareEngineer: 75,
          electricalEngineer: 70,
          aiResearch: 90,
          bioinfo: 74,
          quantTrader: 92,
          quantResearcher: 87,
        },
        {
          month: "July",
          computerFoundations: 89,
          mathFoundations: 75,
          dataFoundations: 79,
          softwareEngineer: 86,
          networkEngineer: 82,
          hardwareEngineer: 77,
          electricalEngineer: 72,
          aiResearch: 92,
          bioinfo: 76,
          quantTrader: 94,
          quantResearcher: 89,
        },
        {
          month: "Aug",
          computerFoundations: 91,
          mathFoundations: 77,
          dataFoundations: 81,
          softwareEngineer: 88,
          networkEngineer: 84,
          hardwareEngineer: 79,
          electricalEngineer: 74,
          aiResearch: 94,
          bioinfo: 78,
          quantTrader: 96,
          quantResearcher: 91,
        },
        {
          month: "Sept",
          computerFoundations: 93,
          mathFoundations: 79,
          dataFoundations: 83,
          softwareEngineer: 90,
          networkEngineer: 86,
          hardwareEngineer: 81,
          electricalEngineer: 76,
          aiResearch: 96,
          bioinfo: 80,
          quantTrader: 98,
          quantResearcher: 93,
        },
      ],
      attemptData: {
        score: 87,
        completion: "12/12 Questions",
        duration: 28,
      },
    },
    // Slightly varied data (added small noise to attempt1's data) for attempt2:
    attempt2: {
      progressData: [
        {
          month: "May",
          computerFoundations: 86,
          mathFoundations: 71,
          dataFoundations: 77,
          softwareEngineer: 84,
          networkEngineer: 78,
          hardwareEngineer: 75,
          electricalEngineer: 69,
          aiResearch: 89,
          bioinfo: 73,
          quantTrader: 91,
          quantResearcher: 86,
        },
        {
          month: "June",
          computerFoundations: 88,
          mathFoundations: 73,
          dataFoundations: 78,
          softwareEngineer: 85,
          networkEngineer: 81,
          hardwareEngineer: 76,
          electricalEngineer: 71,
          aiResearch: 91,
          bioinfo: 75,
          quantTrader: 93,
          quantResearcher: 88,
        },
        {
          month: "July",
          computerFoundations: 89,
          mathFoundations: 76,
          dataFoundations: 80,
          softwareEngineer: 87,
          networkEngineer: 83,
          hardwareEngineer: 78,
          electricalEngineer: 73,
          aiResearch: 93,
          bioinfo: 77,
          quantTrader: 95,
          quantResearcher: 89,
        },
        {
          month: "Aug",
          computerFoundations: 92,
          mathFoundations: 78,
          dataFoundations: 82,
          softwareEngineer: 88,
          networkEngineer: 85,
          hardwareEngineer: 80,
          electricalEngineer: 75,
          aiResearch: 95,
          bioinfo: 79,
          quantTrader: 97,
          quantResearcher: 92,
        },
        {
          month: "Sept",
          computerFoundations: 94,
          mathFoundations: 80,
          dataFoundations: 84,
          softwareEngineer: 91,
          networkEngineer: 86,
          hardwareEngineer: 82,
          electricalEngineer: 77,
          aiResearch: 97,
          bioinfo: 81,
          quantTrader: 99,
          quantResearcher: 94,
        },
      ],
      attemptData: {
        score: 90,
        completion: "12/12 Questions",
        duration: 26,
      },
    },
  },
};

// Utility function to render textual results for background/behavioral tests
const getResultContent = (
  testType: "background" | "behavioral",
  attempt: AttemptType,
  view: "overview" | "analysis"
): string => {
  return testResults[testType][attempt][view];
};

// ===================================
// Render a custom progress bar
// ===================================
const renderBlueOutlineBar = (value: number) => {
  return (
    <div className="h-8 rounded-full border-4 border-[#0059EC] overflow-hidden relative">
      {/* White background */}
      <div className="w-full h-full bg-white" />
      {/* Blue overlay representing filled portion */}
      <div
        className="absolute top-0 left-0 h-full bg-[#0059EC] transition-all duration-500 ease-in-out rounded-full"
        style={{ width: `${value}%` }}
      />
    </div>
  );
};

// ===================================
// Main Component
// ===================================
const TestCenter: React.FC = () => {
  const navigate = useNavigate();

  // Which test? (background, behavioral, technical)
  const [selectedTest, setSelectedTest] = useState<TestType>("background");
  // Which attempt? (attempt1, attempt2)
  const [selectedAttempt, setSelectedAttempt] = useState<AttemptType>("attempt1");

  // ===================================
  // Test Options (cards at the top)
  // ===================================
  const testOptions: TestOption[] = [
    {
      title: "Background",
      duration: "~30 mins",
      description:
        "Sharpen your responses to questions about your education, work experience, and personal projects in our Background interview simulation. This section helps you articulate your professional journey and highlight key achievements to make a strong first impression.",
      route: "/test-center/background",
    },
    {
      title: "Behavioral",
      duration: "~30 mins",
      description:
        "Prepare for common behavioral interview questions in our Behavioral interview simulation. Practice answering questions that assess your teamwork, leadership, and problem solving abilities, ensuring you can effectively demonstrate your soft skills and past experiences.",
      route: "/test-center/behavioral",
    },
    {
      title: "Technical",
      duration: "~60 mins",
      description:
        "Hone your technical interview skills in our Technical interview simulation. This section offers practice questions in math, finance, coding, and problem-solving to help you excel in technical assessments and demonstrate your expertise in your field.",
      route: "/test-center/technical-home",
    },
  ];

  // ===================================
  // Conditionally render the Results
  // ===================================
  const renderResults = () => {
    if (selectedTest === "technical") {
      // For technical, show the chart + attempt stats
      const { progressData, attemptData } = testResults.technical[selectedAttempt];
      return (
        <div className="flex flex-row gap-8 p-6">
          {/* Left section: Score, Completion, Duration */}
          <div className="flex-1 space-y-8">
            {/* Score */}
            <div>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-gray-700 font-medium">Score</h3>
                <span className="text-lg font-semibold text-gray-900">
                  {attemptData.score}%
                </span>
              </div>
              {renderBlueOutlineBar(attemptData.score)}
            </div>
            {/* Completion */}
            <div>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-gray-700 font-medium">Completion</h3>
                <span className="text-lg font-semibold text-gray-900">
                  {attemptData.completion}
                </span>
              </div>
              {renderBlueOutlineBar(100)}
            </div>
            {/* Duration */}
            <div>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-gray-700 font-medium">Duration</h3>
                <span className="text-lg font-semibold text-gray-900">
                  {attemptData.duration} Minutes
                </span>
              </div>
              {renderBlueOutlineBar(75)}
            </div>
          </div>
          {/* Right section: Chart */}
          <div className="flex-1">
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={progressData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <XAxis
                    dataKey="month"
                    tick={{ fontSize: 12, fill: "#4B5563" }}
                    axisLine={{ stroke: "#E5E7EB" }}
                    tickLine={{ stroke: "#E5E7EB" }}
                  />
                  <YAxis
                    ticks={educationalLevels.map((level) => level.value)}
                    tickFormatter={(tick) => {
                      const level = educationalLevels.find((l) => l.value === tick);
                      return level ? level.label : "";
                    }}
                    domain={[0, 100]}
                    tick={{ fontSize: 12, fill: "#4B5563" }}
                    axisLine={{ stroke: "#E5E7EB" }}
                    tickLine={{ stroke: "#E5E7EB" }}
                  />
                  <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#E5E7EB" }} />
                  {Object.keys(colors).map((key) => (
                    <Line
                      key={key}
                      type="monotone"
                      dataKey={key}
                      stroke={colors[key]}
                      strokeWidth={2}
                      dot={{ fill: colors[key], r: 3, strokeWidth: 2 }}
                      activeDot={{ r: 6, strokeWidth: 2 }}
                      name={formatKeyToLabel(key)}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      );
    } else {
      // For background or behavioral, show text results (Overview & Analysis)
      return (
        <div className="flex flex-row gap-8 p-6">
          <div className="flex-1">
            <div className="rounded-lg border border-gray-100 p-6">
              <h3 className="text-lg font-semibold mb-4 text-gray-900">Overview</h3>
              <p className="text-gray-700 leading-relaxed">
                {getResultContent(selectedTest, selectedAttempt, "overview")}
              </p>
            </div>
          </div>
          <div className="flex-1">
            <div className="rounded-lg border border-gray-100 p-6">
              <h3 className="text-lg font-semibold mb-4 text-gray-900">Detailed Analysis</h3>
              <p className="text-gray-700 leading-relaxed">
                {getResultContent(selectedTest, selectedAttempt, "analysis")}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen bg-[#F9F9F9] p-4">
      <div className="max-w-7xl mx-auto">
        {/* Test Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          {testOptions.map((test) => (
            <TestCard key={test.title} test={test} />
          ))}
        </div>

        {/* Results Section */}
        <div className="bg-white rounded-lg shadow-sm">
          <div className="px-6 py-4 border-b border-gray-100">
            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
              <h2 className="text-lg font-bold text-gray-900">Test Results</h2>
              {/* Container for the two selects, aligned to the right and adjacent */}
              <div className="flex items-center gap-2">
                <select
                  value={selectedTest}
                  onChange={(e) => setSelectedTest(e.target.value as TestType)}
                  className="border rounded px-3 py-1.5 bg-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="background">Background</option>
                  <option value="behavioral">Behavioral</option>
                  <option value="technical">Technical</option>
                </select>
                <select
                  value={selectedAttempt}
                  onChange={(e) => setSelectedAttempt(e.target.value as AttemptType)}
                  className="border rounded px-3 py-1.5 bg-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="attempt1">Attempt 1</option>
                  <option value="attempt2">Attempt 2</option>
                </select>
              </div>
            </div>
          </div>
          {/* Results based on selection */}
          {renderResults()}
        </div>
      </div>
    </div>
  );
};

export default TestCenter;